import * as React from 'react'
  /* @jsx mdx */
import { mdx } from '@mdx-js/react';
/* @jsx mdx */

import DefaultLayout from "/opt/build/repo/src/components/layout.js";
export const _frontmatter = {};
const layoutProps = {
  _frontmatter
};
const MDXLayout = DefaultLayout;
export default function MDXContent({
  components,
  ...props
}) {
  return <MDXLayout {...layoutProps} {...props} components={components} mdxType="MDXLayout">


    <ul>
      <li parentName="ul">{`Big Mind: How Collective Intelligence Can Change Our World`}
        <ul parentName="li">
          <li parentName="ul"><a parentName="li" {...{
              "href": "https://www.amazon.com/Geoff-Mulgan/e/B001K7R0L4/ref=dp_byline_cont_book_1"
            }}>{`Geoff Mulgan`}</a></li>
          <li parentName="ul"><a parentName="li" {...{
              "href": "https://www.amazon.com/Big-Mind-Collective-Intelligence-Change/dp/0691170797"
            }}>{`https://www.amazon.com/Big-Mind-Collective-Intelligence-Change/dp/0691170797`}</a></li>
        </ul>
      </li>
    </ul>

    </MDXLayout>;
}
;
MDXContent.isMDXComponent = true;
      